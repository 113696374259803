export interface Config {
  API_URL: string
  TIMEOUT_REQUEST: number
  BASIC_TOKEN: string
}

export const config: Config = {
  API_URL: process.env.REACT_APP_API_URL || '',
  TIMEOUT_REQUEST: Number(process.env.REACT_APP_TIMEOUT_REQUEST) || 30000,
  BASIC_TOKEN: process.env.REACT_APP_BASIC_TOKEN || '',
}

export default config
