import { inject, injectable, registry } from 'tsyringe'
import UserService, { IUserInfo, IUserService } from './services/user.service'

@injectable()
@registry([
  {
    token: 'userService',
    useClass: UserService,
  },
])
export class UserModule {
  constructor(@inject('userService') private userService: IUserService) {}

  getInfo = async (): Promise<IUserInfo | undefined> => {
    return await this.userService.getInfo()
  }

  getPoints = async (): Promise<string | undefined> => {
    return await this.userService.getPoints()
  }
}
