import React, { useState } from 'react'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import {
  IFilial,
  IPaginationParams,
  IResponseFilial,
} from '../../../modules/filial/services/filial.service'
import { Link } from 'react-router-dom'
import { Button, Icon } from '@material-ui/core'
import { useModules } from '../../../modules/ModuleFactory'
import { toast } from 'react-toastify'

const TableFilials = ({
  isLoading,
  filials,
  refetch,
  setFilter,
}: {
  setFilter: (filter: IPaginationParams) => void
  isLoading: boolean
  filials?: IResponseFilial
  refetch: any
}): JSX.Element => {
  const { filialModule } = useModules()
  const [pageSize, setPageSize] = useState(50)

  const handleDeleteFilial = async (filialId: string) => {
    await filialModule.deleteFilial(filialId)
    toast.success('Филиал удалён успешно')
    await refetch()
  }

  const handlePageChange = async (page: number) => {
    setFilter({ limit: 50, page: page + 1 })
  }

  const columns: GridColDef[] = [
    {
      field: 'action',
      headerName: 'Действие',
      width: 135,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => {
        return (
          <>
            <Link to={`/filials/edit/${params.row.cityId}/${params.row.id}`}>
              <Button>
                <Icon color="primary">edit</Icon>
              </Button>
            </Link>
            <Button onClick={async () => await handleDeleteFilial(params.row.id)}>
              <Icon color="primary">delete</Icon>
            </Button>
          </>
        )
      },
    },
    {
      field: 'address',
      headerName: 'Адрес',
      width: 400,
      valueFormatter: (params) => {
        const street = `ул. ${params.row.street}`
        const build = params.row.build ? `д. ${params.row.build}` : ''
        const structure = params.row.structure ? `ст. ${params.row.structure}` : ''
        const corps = params.row.corps ? `к. ${params.row.corps}` : ''

        return `${street} ${build} ${structure} ${corps}`
      },
    },
    {
      field: 'phone',
      headerName: 'Телефон',
      type: 'number',
      headerAlign: 'left',
      align: 'center',
      width: 195,
    },
    {
      field: 'cityName',
      headerName: 'Город',
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      width: 120,
    },
    {
      field: 'scheduleWork',
      headerName: 'График работы',
      type: 'string',
      width: 330,
      valueFormatter: (params) => {
        const aroundTheClock = params.row.aroundTheClock
          ? 'Круглосуточно'
          : `${params.row.workTimeFrom} - ${params.row.workTimeTo}`
        return `${params.row.workDayFrom} - ${params.row.workDayTo} ${aroundTheClock}`
      },
    },
  ]

  return (
    <div className="dataGrid_wrapper" style={{ height: 'calc(100vh - 340px)' }}>
      <DataGrid
        loading={isLoading}
        rows={filials?.data || []}
        columns={columns}
        disableSelectionOnClick
        disableColumnMenu
        showCellRightBorder
        paginationMode="server"
        rowsPerPageOptions={[50]}
        rowCount={filials?.total}
        pageSize={pageSize}
        onPageChange={handlePageChange}
      />
    </div>
  )
}

export default TableFilials
