import { inject, injectable, registry } from 'tsyringe'
import PriceListSaleService, {
  IPriceListSaleService,
  IItemPriceListSale,
  ICreateItemPriceListSale,
  IFilterPriceListSale,
} from './services/priceListSale.service'

@injectable()
@registry([
  {
    token: 'priceListSaleService',
    useClass: PriceListSaleService,
  },
])
export class PriceListSaleModule {
  constructor(
    @inject('priceListSaleService') private priceListSaleService: IPriceListSaleService
  ) {}

  getPriceList = async (): Promise<IItemPriceListSale[] | undefined> => {
    return await this.priceListSaleService.getPriceListSale()
  }

  getPriceListByFilter = async (
    filter: IFilterPriceListSale
  ): Promise<IItemPriceListSale[] | undefined> => {
    return await this.priceListSaleService.getPriceListSaleByFilter(filter)
  }

  saveItemPriceList = async (
    itemPriceListSale: IItemPriceListSale
  ): Promise<Pick<IItemPriceListSale, 'id'> | undefined> => {
    return await this.priceListSaleService.saveItemPriceListSale(itemPriceListSale)
  }

  savePriceList = async (
    priceListSale: IItemPriceListSale[],
    categoryId: number,
    cityIds: number[]
  ): Promise<IItemPriceListSale[] | undefined> => {
    return await this.priceListSaleService.savePriceListSale(priceListSale, categoryId, cityIds)
  }

  createItemPriceList = async (
    itemPriceListSale: ICreateItemPriceListSale
  ): Promise<Pick<IItemPriceListSale, 'id'> | undefined> => {
    return await this.priceListSaleService.createItemPriceListSale(itemPriceListSale)
  }

  deleteItemPriceList = async (
    itemPriceListSale: IItemPriceListSale
  ): Promise<Pick<IItemPriceListSale, 'id'> | undefined> => {
    return await this.priceListSaleService.deleteItemPriceListSale(itemPriceListSale)
  }
}
