import React, { useState } from 'react'
import { setPage } from '../../config/history'
import './filials.sass'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { Page } from '../../components/Page/Page'
import TableFilials from './components/TableFilials'
import MenuItem from '@material-ui/core/MenuItem'
import { useModules } from '../../modules/ModuleFactory'
import { useQuery } from 'react-query'
import { IPaginationParams } from '../../modules/filial/services/filial.service'

export default function Filials(): JSX.Element {
  const [cityId, setCityId] = useState('all')
  const [filter, setFilter] = useState<IPaginationParams>({ limit: 50 })
  const { filialModule } = useModules()

  const { data: dataCities } = useQuery(['cities'], async () => {
    return await filialModule.getCities()
  })

  const {
    refetch,
    isLoading: isLoadingFilials,
    data: filials,
  } = useQuery(['filials', cityId], async () => {
    return cityId !== 'all'
      ? await filialModule.getFilials({ ...filter, cityId: Number(cityId) })
      : await filialModule.getFilials()
  })

  const handleChangeSelect = async (
    event: React.ChangeEvent<{ value: unknown; name?: string }>
  ) => {
    setCityId(event.target.value as string)
  }

  return (
    <Page title={'Филиалы'}>
      <div className="branches_controls">
        <FormControl variant="outlined">
          <InputLabel id="labelCity">Города</InputLabel>
          <Select
            labelId="labelCity"
            id="selectCity"
            value={cityId}
            onChange={handleChangeSelect}
            label="Города"
            name="city"
          >
            <MenuItem value={'all'}>Все</MenuItem>
            {dataCities?.map((el) => {
              return (
                <MenuItem key={el.code} value={el.id}>
                  {el.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <Button
          disabled={cityId === 'all'}
          onClick={() => setPage(`filials/create/${cityId}`)}
          variant="outlined"
        >
          Добавить филиал
        </Button>
      </div>

      <TableFilials
        refetch={refetch}
        isLoading={isLoadingFilials}
        filials={filials}
        setFilter={setFilter}
      />
    </Page>
  )
}
