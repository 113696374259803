import { inject, injectable, registry } from 'tsyringe'
import config from '../../../config'
import Analytics from '../../../utils/Analytics'
import api from '../../../utils/api'
import TokenService, { IToken, ITokenService } from './token.service'
import urljoin from 'url-join'

export interface IUserAuthInfo {
  id: number
  roles: string[]
}

export interface ISignInPhoneValues {
  phone: string
  code: string
}

export interface IIsRegisteredByPhoneValues {
  phone: string
}

export interface IIsRegisteredRes {
  isRegistered: boolean
}

export interface IAuthPhoneService {
  fastSignIn: (data: ISignInPhoneValues) => Promise<IToken | undefined>
  isRegistered: (data: IIsRegisteredByPhoneValues) => Promise<IIsRegisteredRes | undefined>
}

@injectable()
@registry([
  {
    token: 'tokenService',
    useClass: TokenService,
  },
])
class AuthUsernameService implements IAuthPhoneService {
  readonly config = {
    urlApi: urljoin(config.API_URL, 'auth'),
  }

  constructor(@inject('tokenService') private tokenService: ITokenService) {}

  fastSignIn = async ({ code, phone }: ISignInPhoneValues): Promise<IToken | undefined> => {
    try {
      const res = await api.post(urljoin(this.config.urlApi, 'fastSignIn'), {
        code,
        phone,
      })
      return res.data
    } catch (e) {
      Analytics.error(`Не удалость войти ${e}`)
    }
  }

  isRegistered = async ({
    phone,
  }: IIsRegisteredByPhoneValues): Promise<{ isRegistered: boolean } | undefined> => {
    try {
      const res = await api.get(
        urljoin(this.config.urlApi, `isRegisteredUserByPhone?phone=${phone}`)
      )
      return res.data
    } catch (e) {
      Analytics.error(`Не удалость проверить наличие регистрации ${e}`)
    }
  }
}

export default AuthUsernameService
