import React from 'react'

interface Props {
  title: string
  children: React.ReactNode
}

export const Page: React.FC<Props> = ({ title, children }: Props) => (
  <div className="page">
    <div className="container">
      <div className="page_title">
        <h1>{title}</h1>
      </div>

      {children}
    </div>
  </div>
)
