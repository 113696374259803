const LOG_PREFIX = 'LK'
const getHeader = (title: string) => `${`${LOG_PREFIX} ${title}`.padEnd(22)}| `

export default class Analytics {
  public static getInstance(): Analytics {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics()
    }

    return Analytics.instance
  }

  static info(...messages: string[]): void {
    // tslint:disable-next-line:no-console
    console.log(getHeader('Info'), ...messages)
  }
  static error(...messages: string[]): void {
    // tslint:disable-next-line:no-console
    console.error(getHeader('Error'), ...messages)
  }

  static warn(...messages: string[]): void {
    // tslint:disable-next-line:no-console
    console.warn(getHeader('Warning'), ...messages)
  }

  static deprecated(...messages: string[]): void {
    // tslint:disable-next-line:no-console
    console.warn(getHeader('Deprecated'), ...messages)
  }

  static log(...messages: string[]): void {
    // tslint:disable-next-line:no-console
    console.log(getHeader('Log'), ...messages)
  }
  private static instance: Analytics
}
