import { inject, injectable, registry } from 'tsyringe'
import config from '../../../config'
import Analytics from '../../../utils/Analytics'
import api from '../../../utils/api'
import TokenService, { IToken, ITokenService } from './token.service'
import urljoin from 'url-join'

export interface IUserAuthInfo {
  id: number
  roles: string[]
}

export interface IAuthUsernameService {
  signInUsername: (user: ISignInUsernameValues) => Promise<IToken | undefined>
  signInBasic: (user: ISignInBasicValues) => Promise<string | undefined>
  auth: () => Promise<IUserAuthInfo | undefined>
}

export interface ISignInUsernameValues {
  username: string
  password: string
  remember?: boolean
}

export interface ISignInBasicValues {
  username: string
  password: string
}

@injectable()
@registry([
  {
    token: 'tokenService',
    useClass: TokenService,
  },
])
class AuthUsernameService implements IAuthUsernameService {
  readonly config = {
    urlApi: urljoin(config.API_URL, 'user'),
  }

  constructor(@inject('tokenService') private tokenService: ITokenService) {}

  auth = async (): Promise<IUserAuthInfo | undefined> => {
    try {
      const res = await api.post('/')
      return res.data
    } catch (e) {
      Analytics.error(`Не удалость проверить подлинность токена ${e}`)
    }
  }

  signInUsername = async (user: ISignInUsernameValues): Promise<IToken | undefined> => {
    try {
      const res = await api.post(
        urljoin(this.config.urlApi, 'signInUsername'),
        JSON.stringify(user)
      )
      return res.data
    } catch (e) {
      Analytics.error(`Не удалость авторизоваться ${e}`)
    }
  }

  signInBasic = async (user: ISignInBasicValues): Promise<string | undefined> => {
    try {
      const res = await api.post(urljoin(this.config.urlApi, 'login'), JSON.stringify(user))
      return res.data.data.token
    } catch (e) {
      Analytics.error(`Не удалость авторизоваться ${e}`)
    }
  }
}

export default AuthUsernameService
