import axios from 'axios'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import TokenService from '../modules/auth/services/token.service'
import { PAGES_ROUTES } from '../config/routes'
import config from '../config'
import Analytics from './Analytics'

export interface IResponse {
  statusCode: number
  message: string
  error?: string
}

export const DEFAULT_ERROR =
  'Произошла неизвестная ошибка. Обновите страницу или повторите попытку позже.'
const axiosInstance = axios.create({
  baseURL: config.API_URL,
  responseType: 'json',
  timeout: config.TIMEOUT_REQUEST,
})

axiosInstance.interceptors.request.use(
  async (config) => {
    const tokenService = new TokenService()
    const token = await tokenService.getBasicToken()
    const contentType = config.method === 'post' ? { 'content-type': 'application/json' } : {}
    return {
      ...config,
      headers: {
        ...contentType,
        ...config.headers,
        ...(token
          ? {
              Authorization: `Basic ${token}`,
            }
          : {}),
      },
    }
  },
  (error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.status === 401) {
      const tokenService = new TokenService()
      await tokenService.logout()
      if (window.location.pathname !== PAGES_ROUTES.LOGIN.path)
        window.location.href = PAGES_ROUTES.LOGIN.path
    }

    const parseErrors = get(error, 'response.error')

    if (!parseErrors) {
      toast.error(get(error, 'response.data.message', DEFAULT_ERROR))
      Analytics.log(`Произошла ошибка во время выполнения запроса ` + error)
    }

    return Promise.reject(error)
  }
)

export default axiosInstance
