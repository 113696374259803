import React, { useState } from 'react'
import './login.sass'
import { useModules } from '../../modules/ModuleFactory'
import ROUTES from '../../config/routes'
import { Button, TextField } from '@material-ui/core'

export const Login: React.FC = () => {
  const { authModule } = useModules()

  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmitLogin = async (): Promise<void> => {
    const res = await authModule.signInBasic({ username: login, password })
    if (res) {
      window.location.replace(ROUTES.ADMIN.path)
    }
  }

  return (
    <div className="login_page">
      <div className="login_wrapper">
        <form
          onSubmit={async (e) => {
            e.preventDefault()
            await handleSubmitLogin()
          }}
        >
          <div className="login_content">
            <h1>Вход</h1>
            <div className="input_group">
              <TextField
                onChange={(e) => setLogin(e.target.value)}
                value={login}
                className="login_input"
                required
                label="Логин"
                type="text"
                id="login"
                variant="outlined"
              />
            </div>
            <div className="input_group">
              <TextField
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                className="login_input"
                required
                label="Пароль"
                type="password"
                id="password"
                variant="outlined"
              />
            </div>
            <Button type="submit" variant={'outlined'}>
              Войти
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
