import { inject, injectable, registry } from 'tsyringe'
import CallbackService, {
  IPaginationParams,
  ICallbackService,
  IResponseCallback,
} from './services/callback.service'

@injectable()
@registry([
  {
    token: 'callbackService',
    useClass: CallbackService,
  },
])
export class CallbackModule {
  constructor(@inject('callbackService') private callbackService: ICallbackService) {}

  getCallbacks = async (
    paginationParams?: IPaginationParams
  ): Promise<IResponseCallback | undefined> => {
    return await this.callbackService.getCallbacks(paginationParams)
  }
}
