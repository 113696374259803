import React, { useState } from 'react'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import {
  IPaginationParams,
  IResponseCallback,
} from '../../../modules/callback/services/callback.service'
import moment from 'moment/moment'

const columns: GridColDef[] = [
  { field: 'id', headerName: '№', width: 90 },
  {
    field: 'name',
    headerName: 'Имя',
    width: 260,
  },
  {
    field: 'phone',
    headerName: 'Телефон',
    type: 'number',
    headerAlign: 'left',
    align: 'center',
    width: 170,
    // eslint-disable-next-line react/display-name
    renderCell: (params) => {
      return <a href={`tel:${params.row.phone}`}>{`${params.row.phone}`}</a>
    },
  },
  {
    field: 'city',
    headerName: 'Город',
    type: 'string',
    width: 190,
    valueFormatter: (params) => (params.row.city?.name ? params.row.city?.name : '-'),
  },
  {
    field: 'createdAt',
    headerName: 'Дата (по МСК)',
    type: 'dateTime',
    width: 160,
    valueFormatter: (params) =>
      moment(params.row.createdAt, 'DD-MM-YYYY HH:mm').add(3, 'hours').format('DD.MM.YYYY HH:mm'),
  },
  // {
  //   field: 'comment',
  //   headerName: 'Коментарий',
  //   sortable: false,
  //   minWidth: 200,
  //   width: 600,
  //   // eslint-disable-next-line react/display-name
  //   renderCell: (params) => {
  //     return <div style={{ overflow: 'auto', height: '55px' }}>{`${params.row.comment}`}</div>
  //   },
  // },
  {
    field: 'weight',
    headerName: 'Вес в г.',
    type: 'string',
    width: 110,
    valueFormatter: (params) => (params.row.weight ? params.row.weight : '-'),
  },
  {
    field: 'material',
    headerName: 'Материал',
    type: 'string',
    width: 270,
    valueFormatter: (params) => (params.row.material ? params.row.material : '-'),
  },
]

const TableRequests = ({
  isLoading,
  callbacks,
  setFilter,
}: {
  setFilter: (filter: IPaginationParams) => void
  isLoading: boolean
  callbacks?: IResponseCallback
}): JSX.Element => {
  const [pageSize, setPageSize] = useState(50)
  //const [page, setPage] = useState(0)

  // const handlePageSizeChange = async (pageSize: number) => {
  //   setPageSize(pageSize)
  //   setFilter({ limit: pageSize, page: page })
  // }

  const handlePageChange = async (page: number) => {
    setFilter({ limit: 50, page: page + 1 })
  }

  return (
    <div className="dataGrid_wrapper" style={{ height: 'calc(100vh - 250px)' }}>
      <DataGrid
        loading={isLoading}
        rows={callbacks?.data || []}
        columns={columns}
        disableSelectionOnClick
        disableColumnMenu
        showCellRightBorder
        paginationMode="server"
        rowsPerPageOptions={[50]}
        rowCount={callbacks?.total}
        pageSize={pageSize}
        // onPageSizeChange={handlePageSizeChange}
        onPageChange={handlePageChange}
      />
    </div>
  )
}

export default TableRequests
