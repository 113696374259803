import React, { useEffect, useState } from 'react'
import { Page } from '../../components/Page/Page'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useQuery } from 'react-query'
import { useModules } from '../../modules/ModuleFactory'
import { toast } from 'react-toastify'
import { Button } from '@material-ui/core'
import { SelectCities } from './components/SelectCities'
import { IItemPriceList } from '../../modules/priceList/services/priceList.service'
import BuyPriceList from './components/buyPriceList/BuyPriceList'
import SellPriceList from './components/sellPriceList/SellPriceList'
import { Redirect, Route, Switch } from 'react-router'
import { useParams } from 'react-router-dom'

export const itemPriceListDefault: IItemPriceList = {
  id: 0,
  cash: 0,
  checkingAccount: 0,
  finenessOf: 0,
  category: '',
  city: '',
  update: false, //флаг возможности редактирования, если true то можно редактировать
}

export default function PriceLists(): JSX.Element {
  const { categoryModule, priceListModule, priceListSaleModule, filialModule } = useModules()
  const { typePrice } = useParams<{ typePrice: 'sell' | 'buy' | undefined }>()

  const [categoryId, setCategoryId] = useState('all')
  const [cityId, setCityId] = useState('all')
  const [cityIds, setCityIds] = useState<number[]>([])
  const [defaultCities, setDefaultCities] = useState<{ [key: number]: boolean }>({})
  const [isEditMode, setEditMode] = useState(false)
  const [editItemPriceListIndex, setEditItemPriceListIndex] = useState<number | undefined>()
  const [editItemPriceList, setEditItemPriceList] = useState<IItemPriceList>(itemPriceListDefault)
  const [localPriceList, setLocalPriceList] = useState<IItemPriceList[]>()

  const { isLoading: isLoadingCity, data: dataCities } = useQuery(['cities'], async () => {
    return await filialModule.getCities()
  })

  const { isLoading: isLoadingCategory, data: dataCategories } = useQuery(
    ['categories', typePrice],
    async () => {
      return typePrice === 'sell'
        ? await categoryModule.getCategoriesSale()
        : await categoryModule.getCategories()
    },
    {
      enabled: categoryId !== '0' && cityId !== '0' && !!typePrice,
    }
  )

  const {
    isLoading,
    data: priceListData,
    refetch,
  } = useQuery(
    ['priceList', categoryId, cityId, typePrice],
    async () => {
      const modulePriceList = typePrice === 'sell' ? priceListSaleModule : priceListModule
      return await modulePriceList.getPriceListByFilter({
        categoryId: Number(categoryId),
        cityId: Number(cityId),
      })
    },
    {
      enabled: categoryId !== 'all' && cityId !== 'all' && !!typePrice,
    }
  )

  //Если выбрали город врубаем режим редактирования
  useEffect(() => {
    if (cityIds.length > 1) setEditMode(true)
  }, [cityIds])

  useEffect(() => {
    setLocalPriceList(priceListData)
  }, [priceListData])

  const calculatePriceList = async (editItemPriceList: IItemPriceList) => {
    const newLocalPriceList = localPriceList
      ? await priceListModule.calculatePriceList(localPriceList, editItemPriceList)
      : []
    setLocalPriceList(newLocalPriceList)
  }

  const handleChangeCity = async (event: React.ChangeEvent<{ value: unknown; name?: string }>) => {
    await handleCancelEdit()
    setCityId(event.target.value as string)
  }

  const handleChangeCategory = async (
    event: React.ChangeEvent<{ value: unknown; name?: string }>
  ) => {
    setCategoryId(event.target.value as string)
    await handleCancelEdit()
  }

  const handleSubmitPriceList = async () => {
    if (!localPriceList || categoryId === 'all' || cityId === 'all') {
      console.error('Недостаточно данных для расчёта')
      return
    }

    const module = typePrice === 'sell' ? priceListSaleModule : priceListModule

    const res = await module.savePriceList(localPriceList, Number(categoryId), [
      Number(cityId),
      ...cityIds,
    ])
    if (res) toast.success('Прайс лист успешно обновлён')
    await handleCancelEdit()
    await refetch()
  }

  const handleCashChange = async (
    index: number,
    event: React.FormEvent<HTMLInputElement>
  ): Promise<void> => {
    const editItem = { ...editItemPriceList, cash: Number(event.currentTarget.value) }
    setEditItemPriceList(editItem)
    await calculatePriceList(editItem)
  }

  const handleCheckingAccountChange = async (
    index: number,
    event: React.FormEvent<HTMLInputElement>
  ): Promise<void> => {
    const editItem = {
      ...editItemPriceList,
      checkingAccount: Number(event.currentTarget.value),
    }
    setEditItemPriceList(editItem)
    await calculatePriceList(editItem)
  }

  const handleCancelEdit = async (): Promise<void> => {
    setEditMode(false)
    setCityIds([])
    setLocalPriceList(priceListData)
    setDefaultCities({})
  }
  const clearForm = () => {
    setCityId('all')
    setCategoryId('all')
  }

  return (
    <Page title={typePrice === 'buy' ? 'Прайс листы Скупка' : 'Прайс листы Продажа'}>
      <div className="priceListControl">
        <div className="select_group">
          <div className="select_group_item">
            <FormControl variant="outlined">
              <InputLabel id="labelCity">Города</InputLabel>
              <Select
                disabled={isLoadingCategory}
                labelId="labelCity"
                id="selectCity"
                value={cityId}
                onChange={handleChangeCity}
                label="Города"
                name="city"
              >
                <MenuItem value={'all'}>Все</MenuItem>
                {dataCities?.map((el) => {
                  return (
                    <MenuItem key={el.code} value={el.id}>
                      {el.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>

          <div className="select_group_item">
            <FormControl variant="outlined">
              <InputLabel id="labelCategory">Категория</InputLabel>
              <Select
                disabled={isLoadingCity}
                labelId="labelCategory"
                id="selectCategory"
                value={categoryId}
                onChange={handleChangeCategory}
                label="Категория"
                name="category"
              >
                <MenuItem value={'all'}>Все</MenuItem>
                {dataCategories?.map((el) => {
                  return !el.parentId ? (
                    <MenuItem key={el.id} value={el.id}>
                      {el.title}
                    </MenuItem>
                  ) : null
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="buttons_group">
          <div>
            <Button disabled={!isEditMode} variant="outlined" onClick={handleSubmitPriceList}>
              Сохранить
            </Button>
            <Button disabled={!isEditMode} variant="outlined" onClick={handleCancelEdit}>
              Отменить
            </Button>
          </div>
        </div>
      </div>
      {dataCities && categoryId !== 'all' ? (
        <SelectCities
          callbackSelectCities={setCityIds}
          defaultCityId={Number(cityId)}
          cities={dataCities}
          defaultCities={defaultCities}
          setEditMode={setEditMode}
        />
      ) : null}
      <Switch>
        <Route
          path={'/priceList/sell'}
          render={() => (
            <SellPriceList
              isLoading={isLoading}
              isEditMode={isEditMode}
              editItemPriceListIndex={editItemPriceListIndex}
              handleCheckingAccountChange={handleCheckingAccountChange}
              handleSubmitPriceList={handleSubmitPriceList}
              setEditMode={setEditMode}
              setEditItemPriceListIndex={setEditItemPriceListIndex}
              setEditItemPriceList={setEditItemPriceList}
              localPriceList={localPriceList}
              clearForm={clearForm}
            />
          )}
        />
        <Route
          path={'/priceList/buy'}
          render={() => (
            <BuyPriceList
              isLoading={isLoading}
              isEditMode={isEditMode}
              editItemPriceListIndex={editItemPriceListIndex}
              handleCheckingAccountChange={handleCheckingAccountChange}
              handleSubmitPriceList={handleSubmitPriceList}
              setEditMode={setEditMode}
              setEditItemPriceListIndex={setEditItemPriceListIndex}
              setEditItemPriceList={setEditItemPriceList}
              handleCashChange={handleCashChange}
              handleChangeCategory={handleChangeCategory}
              handleChangeCity={handleChangeCity}
              localPriceList={localPriceList}
              clearForm={clearForm}
            />
          )}
        />
        <Redirect to={'/priceList/sell'} />
      </Switch>
    </Page>
  )
}
