import { injectable } from 'tsyringe'
import config from '../../../config'
import Analytics from '../../../utils/Analytics'
import api from '../../../utils/api'
import urljoin from 'url-join'

export interface ICategory {
  id: number
  code: string
  title: string
  parentId?: number
}

export interface ICategoryService {
  getCategories: () => Promise<ICategory[] | undefined>
  getCategoriesSale: () => Promise<ICategory[] | undefined>
}

@injectable()
class CategoryService implements ICategoryService {
  readonly config = {
    urlApi: urljoin(config.API_URL, 'category'),
  }

  getCategories = async (): Promise<ICategory[] | undefined> => {
    try {
      const res = await api.get(urljoin(this.config.urlApi, 'all'))
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о категории ${e}`)
    }
  }

  getCategoriesSale = async (): Promise<ICategory[] | undefined> => {
    try {
      const res = await api.get(urljoin(this.config.urlApi, 'sale/all'))
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о категории ${e}`)
    }
  }
}

export default CategoryService
