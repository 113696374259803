import React, { Context } from 'react'
// import { container } from 'tsyringe'
import AuthUsernameService, { IAuthUsernameService } from './auth/services/authUsername.service'
import TokenService, { ITokenService } from './auth/services/token.service'
import FilialService, { IFilialService } from './filial/services/filial.service'
import CategoryService, { ICategoryService } from './category/services/category.service'
import PriceListService, { IPriceListService } from './priceList/services/priceList.service'
import CallbackService, { ICallbackService } from './callback/services/callback.service'

export interface ServiceFactory {
  authUsernameService: IAuthUsernameService
  tokenService: ITokenService
  filialService: IFilialService
  categoryService: ICategoryService
  priceListService: IPriceListService
  repairService: ICallbackService
}

export const serviceContext = (): Context<ServiceFactory> => {
  return React.createContext({
    authUsernameService: new AuthUsernameService(new TokenService()) as IAuthUsernameService, //container.resolve<IAuthUsernameService>(AuthUsernameService),
    tokenService: new TokenService() as ITokenService, //container.resolve<ITokenService>(TokenService),
    filialService: new FilialService() as IFilialService, //container.resolve<IFilialService>(FilialService),
    categoryService: new CategoryService() as ICategoryService,
    priceListService: new PriceListService() as IPriceListService,
    repairService: new CallbackService() as ICallbackService,
  })
}

export const useServices = (): ServiceFactory => React.useContext(serviceContext())
