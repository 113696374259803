import React, { useEffect } from 'react'
import { IItemPriceList } from '../../../../modules/priceList/services/priceList.service'
import { Button, Icon } from '@material-ui/core'

interface ISellPriceListPropsType {
  isLoading: boolean
  localPriceList?: IItemPriceList[]
  isEditMode: boolean
  editItemPriceListIndex: number | undefined
  handleCheckingAccountChange: (index: number, e: React.FormEvent<HTMLInputElement>) => void
  handleSubmitPriceList: () => void
  setEditMode: (value: boolean) => void
  setEditItemPriceListIndex: (index: number) => void
  setEditItemPriceList: (item: IItemPriceList) => void
  clearForm: () => void
}

export default function SellPriceList(props: ISellPriceListPropsType): JSX.Element {
  const {
    isLoading,
    localPriceList,
    isEditMode,
    editItemPriceListIndex,
    handleCheckingAccountChange,
    handleSubmitPriceList,
    setEditMode,
    setEditItemPriceListIndex,
    setEditItemPriceList,
    clearForm,
  } = props
  useEffect(() => {
    clearForm()
  }, [])
  return (
    <>
      <div className="table">
        <div className="table_head">
          <div className="table_head_title">
            <p>Проба</p>
          </div>
          <div className="table_head_title">
            <p>На расчётный счёт</p>
          </div>
          <div className="table_head_title">
            <p>Действия</p>
          </div>
        </div>
        <div className="table_body">
          {isLoading ? (
            <div className={'loader'}>Загрузка...</div>
          ) : (
            <>
              {!localPriceList ? (
                <div className="labelEmptyPriceList">Выберите город и категорию</div>
              ) : null}

              {localPriceList?.map((item, index) =>
                isEditMode && editItemPriceListIndex === index ? (
                  <div className="table_body_item" key={item.id}>
                    <div className="table_body_item_title">{item.finenessOf}</div>
                    <input
                      className="table_body_item_checkingAccount"
                      required={true}
                      type="number"
                      defaultValue={item.checkingAccount}
                      onChange={(e) => handleCheckingAccountChange(index, e)}
                    />
                    <div className="actionTools">
                      <Button onClick={handleSubmitPriceList}>
                        <Icon color="primary">save</Icon>
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="table_body_item" key={item.id}>
                    <div className="table_body_item_title">{item.finenessOf}</div>
                    <div className="table_body_item_checkingAccount">{item.checkingAccount}</div>
                    <div className="actionTools">
                      {item.update ? (
                        <Button
                          onClick={() => {
                            setEditMode(true)
                            setEditItemPriceListIndex(index)
                            setEditItemPriceList(item)
                          }}
                        >
                          <Icon color="primary">edit</Icon>
                        </Button>
                      ) : null}
                    </div>
                  </div>
                )
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
