import { injectable } from 'tsyringe'
import config from '../../../config'
import Analytics from '../../../utils/Analytics'
import api from '../../../utils/api'
import urljoin from 'url-join'

export interface IUserInfo {
  phone: string
  middleName: string
  lastName: string
  firstName: string
}

export interface IUserService {
  getInfo: () => Promise<IUserInfo | undefined>
  getPoints: () => Promise<string | undefined>
}

@injectable()
class UserService implements IUserService {
  readonly config = {
    urlApi: urljoin(config.API_URL, 'user'),
  }

  getInfo = async (): Promise<IUserInfo | undefined> => {
    try {
      const res = await api.get(urljoin(this.config.urlApi, `info`))
      return res.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о юзере ${e}`)
    }
  }

  getPoints = async (): Promise<string | undefined> => {
    try {
      const res = await api.get(urljoin(this.config.urlApi, `getPoints`))
      return res.data
    } catch (e) {
      Analytics.error(`Не удалость получить баллы юзера ${e}`)
    }
  }
}

export default UserService
