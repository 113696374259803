import { injectable } from 'tsyringe'
import config from '../../../config'
import Analytics from '../../../utils/Analytics'
import api from '../../../utils/api'
import urljoin from 'url-join'

export interface IPaginationParams {
  page?: number
  limit?: number
}

/*
 * @param array $data
 * @param int   $total - кол-во записей всего
 * @param int   $limit - кол-во записей на страницу
 * @param int   $perPage - кол-во записей на текущей странице
 * @param int   $currentPage - текущая страницы
 * @param int   $totalPage - кол-во страниц
 */
export interface IResponseFilial {
  data: IFilial[] | []
  currentPage: number
  total: number
  limit: number
  perPage: number
  totalPage: number
  message: string
  code: number
}

export interface ICity {
  id: number
  name: string
  code: string
}

export interface IFilial {
  name: string
  phone: string // format "79347882391,12878348122,329930232332"
  street: string
  build?: string
  structure?: string
  corps?: string
  cityId: number
  workDayFrom: string
  workDayTo: string
  workTimeFrom: string
  workTimeTo: string
  aroundTheClock?: boolean
  latitude: number
  longitude: number
  serviceCenter?: boolean
  receivingPoint?: boolean
}

export interface IResponseFilialByFilter {
  data: IFilial[] | []
  pageCurrent: number
  total: number
  pageCount: number
  message: number
  code: number
}

export interface IFilialService {
  getFilials: (
    filter?: IPaginationParams & { cityId?: number }
  ) => Promise<IResponseFilial | undefined>
  getFilialById: (id: number) => Promise<IFilial | undefined>
  createFilial: (filial: IFilial) => Promise<Partial<IFilial> | undefined>
  saveFilial: (filial: IFilial, filialId: string) => Promise<Partial<IFilial> | undefined>
  deleteFilial: (filialId: string) => Promise<Partial<IFilial> | undefined>
}

@injectable()
class FilialService implements IFilialService {
  readonly config = {
    urlApi: urljoin(config.API_URL, 'filial'),
  }

  getFilials = async (
    filter?: IPaginationParams & { cityId?: number }
  ): Promise<IResponseFilial | undefined> => {
    try {
      const res = await api.get(
        urljoin(
          this.config.urlApi,
          `all`,
          filter?.page ? `?page=${filter.page}` : ``,
          filter?.limit ? `?limit=${filter.limit}` : ``,
          filter?.cityId ? `?cityId=${filter.cityId}` : ``
        )
      )
      return res.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о филиалах ${e}`)
    }
  }

  getFilialById = async (id: number): Promise<IFilial | undefined> => {
    try {
      const res = await api.get(urljoin(this.config.urlApi, String(id)))
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о филиале ${e}`)
    }
  }

  createFilial = async (filial: IFilial): Promise<Partial<IFilial> | undefined> => {
    try {
      const res = await api.post(urljoin(this.config.urlApi), JSON.stringify(filial))
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость создать филиал ${e}`)
    }
  }

  saveFilial = async (filial: IFilial, filialId: string): Promise<Partial<IFilial> | undefined> => {
    try {
      const res = await api.put(
        urljoin(this.config.urlApi, String(filialId)),
        JSON.stringify(filial)
      )
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость сохранить филиал ${e}`)
    }
  }

  deleteFilial = async (filialId: string): Promise<Partial<IFilial> | undefined> => {
    try {
      const res = await api.delete(urljoin(this.config.urlApi, String(filialId)))
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость удалить филиал ${e}`)
    }
  }
}

export default FilialService
