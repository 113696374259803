import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ICity } from '../../../modules/filial/services/city.service'
import './selectCities.sass'

interface ISelectCitiesProps {
  cities: ICity[]
  defaultCityId?: number
  callbackSelectCities: (cityIds: number[]) => void
  defaultCities: { [key: number]: boolean }
  setEditMode: (value: boolean) => void
}

const useStyles = makeStyles(() => ({
  root: {
    height: '180px',
    overflow: 'auto',
    width: '100%',
  },
  rootMuiFormControl: {
    width: '100%',
  },
}))

export const SelectCities: React.FC<ISelectCitiesProps> = ({
  defaultCities,
  cities,
  defaultCityId,
  callbackSelectCities,
  setEditMode,
}: ISelectCitiesProps) => {
  const classes = useStyles()
  const [cityIds, setCityIds] = useState<{ [key: number]: boolean }>({})

  useEffect(() => {
    if (defaultCityId) setCityIds({ [defaultCityId]: true })
  }, [defaultCityId, defaultCities])

  const handleChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const newCityIds = { ...cityIds, [Number(event.target.name)]: event.target.checked }
    setCityIds(newCityIds)
    callbackSelectCities(
      Object.keys(newCityIds)
        .filter((el) => newCityIds[Number(el)])
        .map((el) => Number(el))
    )
  }
  const handleSelectAllCities = () => {
    const allCities = Object.assign({}, ...cities.map((city) => ({ [city.id]: true })))
    setCityIds(allCities)
    callbackSelectCities(Object.keys(allCities).map((el) => Number(el)))
    setEditMode(true)
  }

  return (
    <div className="selectCities">
      <FormControl
        classes={{ root: classes.rootMuiFormControl }}
        component="fieldset"
        variant="standard"
        size="small"
      >
        <FormLabel component="legend">
          Выбирите города к которым применится текущий прайс лист
        </FormLabel>
        <div className="buttons_group">
          <Button variant="contained" onClick={handleSelectAllCities}>
            Выбрать все города
          </Button>
        </div>
        <FormGroup classes={{ root: classes.root }}>
          {cities.map((city) => (
            <FormControlLabel
              key={city.id}
              control={
                <Checkbox
                  disabled={defaultCityId === city.id}
                  size="small"
                  checked={city.id in cityIds && cityIds[city.id]}
                  onChange={handleChange}
                  name={String(city.id)}
                />
              }
              label={city.name}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  )
}
