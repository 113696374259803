import { injectable } from 'tsyringe'
import config from '../../../config'
import Analytics from '../../../utils/Analytics'
import api from '../../../utils/api'
import urljoin from 'url-join'

export interface IItemPriceList {
  id: number
  cash: number
  checkingAccount: number
  finenessOf: number
  category: string
  city: string
  update: boolean //флаг возможности редактирования, если true то можно редактировать
}

export interface IFilterPriceList {
  cityId: number
  categoryId: number
}

export type ICreateItemPriceList = Omit<IItemPriceList, 'id'>

export interface IPriceListService {
  getPriceListByFilter: (filter: IFilterPriceList) => Promise<IItemPriceList[] | undefined>
  getPriceList: () => Promise<IItemPriceList[] | undefined>
  saveItemPriceList: (
    itemPriceList: IItemPriceList
  ) => Promise<Pick<IItemPriceList, 'id'> | undefined>
  savePriceList: (
    priceList: IItemPriceList[],
    categoryId: number,
    cityIds: number[]
  ) => Promise<IItemPriceList[] | undefined>
  createItemPriceList: (
    itemPriceList: ICreateItemPriceList
  ) => Promise<Pick<IItemPriceList, 'id'> | undefined>
  deleteItemPriceList: (
    itemPriceList: IItemPriceList
  ) => Promise<Pick<IItemPriceList, 'id'> | undefined>
  calculatePriceList: (
    priceList: IItemPriceList[],
    mainItemPriceList: IItemPriceList
  ) => Promise<IItemPriceList[] | undefined>
}

@injectable()
class PriceListService implements IPriceListService {
  readonly config = {
    urlApi: urljoin(config.API_URL, 'priceList'),
  }

  calculatePriceList = async (
    priceList: IItemPriceList[],
    mainItemPriceList: IItemPriceList
  ): Promise<IItemPriceList[] | undefined> => {
    return priceList.map((el) => {
      if (el.id != mainItemPriceList.id && el.update) return el // не обновляем те которые мы можем редактировать
      const newCash = (mainItemPriceList.cash / mainItemPriceList.finenessOf) * el.finenessOf
      const newCheckingAccount =
        (mainItemPriceList.checkingAccount / mainItemPriceList.finenessOf) * el.finenessOf
      return {
        ...el,
        cash: Number(newCash.toFixed(0)),
        checkingAccount: Number(newCheckingAccount.toFixed(0)),
      }
    })
  }

  getPriceList = async (): Promise<IItemPriceList[] | undefined> => {
    try {
      const res = await api.get(urljoin(this.config.urlApi, 'all'))
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о категории ${e}`)
    }
  }

  getPriceListByFilter = async (
    filter: IFilterPriceList
  ): Promise<IItemPriceList[] | undefined> => {
    try {
      const { cityId, categoryId } = filter
      const res = await api.get(
        urljoin(this.config.urlApi, 'byFilter', `?cityId=${cityId}`, `?categoryId=${categoryId}`)
      )
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о прайсах ${e}`)
    }
  }

  saveItemPriceList = async (
    itemPriceList: IItemPriceList
  ): Promise<Pick<IItemPriceList, 'id'> | undefined> => {
    try {
      const res = await api.put(
        urljoin(this.config.urlApi, String(itemPriceList.id)),
        itemPriceList
      )
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о прайсах ${e}`)
    }
  }

  savePriceList = async (
    priceList: IItemPriceList[],
    categoryId: number,
    cityIds: number[]
  ): Promise<[] | undefined> => {
    const serverTypePriceList: {
      [key: number]: {
        cash: number
        checkingAccount: number
      }
    } = {} // кароч бек принимает объект с ключами проб (костыль!!!!)
    for (const item of priceList) {
      serverTypePriceList[item.finenessOf] = {
        cash: item.cash,
        checkingAccount: item.checkingAccount,
      }
    }

    const res = await api.put(urljoin(this.config.urlApi), {
      priceList: serverTypePriceList,
      city: cityIds,
      categoryId: categoryId,
    })
    return res.data.data
  }

  createItemPriceList = async (
    itemPriceList: ICreateItemPriceList
  ): Promise<Pick<IItemPriceList, 'id'> | undefined> => {
    try {
      const res = await api.post(urljoin(this.config.urlApi), itemPriceList)
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о прайсах ${e}`)
    }
  }

  deleteItemPriceList = async (
    itemPriceList: IItemPriceList
  ): Promise<Pick<IItemPriceList, 'id'> | undefined> => {
    try {
      const res = await api.delete(urljoin(this.config.urlApi, String(itemPriceList.id)))
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о прайсах ${e}`)
    }
  }
}

export default PriceListService
