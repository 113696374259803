import React from 'react'
import ReactMaskedInput, { Props } from 'react-input-mask'

type ITextMaskCustom = Props

export const TextMaskCustom: React.FC<ITextMaskCustom> = (props: ITextMaskCustom) => {
  const { mask, children, maskPlaceholder, alwaysShowMask, ...other } = props
  return (
    <ReactMaskedInput
      {...other}
      mask={mask}
      maskPlaceholder={maskPlaceholder}
      alwaysShowMask={alwaysShowMask}
    >
      {children}
    </ReactMaskedInput>
  )
}
