import React, { useState } from 'react'
import { setPage } from '../../config/history'
import Logo from '../../assets/logo.svg'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/authProvider'
import { set } from 'lodash'

export const Header: React.FC = () => {
  const { isAuthorized, logout } = useAuth()

  const handleExitLKButton = () => {
    logout()
  }

  const actionButton: JSX.Element = isAuthorized ? (
    <div>
      <button className="nav_link" onClick={handleExitLKButton}>
        Выйти
      </button>
    </div>
  ) : (
    <></>
  )
  const menu: JSX.Element = isAuthorized ? (
    <div className="nav">
      <div className="nav_link">
        Прайс листы
        <div className="active_menu">
          <Link className="active_menu_item" to="/priceList/buy">
            Скупка
          </Link>
          <Link className="active_menu_item" to="/priceList/sell">
            Продажа
          </Link>
        </div>
      </div>
      <button onClick={() => setPage('requests')} className="nav_link">
        Заявки <div className="underline" />
      </button>
      <button onClick={() => setPage('filials')} className="nav_link">
        Филиалы <div className="underline" />
      </button>
    </div>
  ) : (
    <></>
  )

  return (
    <header>
      <div className="container">
        <div className="header_wrapper">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          {menu}
          {actionButton}
        </div>
      </div>
    </header>
  )
}
