import React, { useState } from 'react'
import { Page } from '../../components/Page/Page'
import TableRequests from './components/TableRequests'
import { useModules } from '../../modules/ModuleFactory'
import { useQuery } from 'react-query'
import { IPaginationParams } from '../../modules/callback/services/callback.service'

export default function Requests(): JSX.Element {
  const { repairModule } = useModules()
  const [filter, setFilter] = useState<IPaginationParams>({ limit: 50 })

  const { data: dataCallbacks, isLoading } = useQuery(['callbacks', filter], async () => {
    return await repairModule.getCallbacks(filter)
  })

  return (
    <Page title={'Заявки'}>
      <TableRequests isLoading={isLoading} callbacks={dataCallbacks} setFilter={setFilter} />
    </Page>
  )
}
