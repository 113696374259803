import React from 'react'
// import { container } from 'tsyringe'
import { AuthModule } from './auth/auth.module'
import { UserModule } from './user/user.module'
import { FilialModule } from './filial/filial.module'
import FilialService from './filial/services/filial.service'
import CityService from './filial/services/city.service'
import { CategoryModule } from './category/category.module'
import CategoryService from './category/services/category.service'
import { PriceListModule } from './priceList/priceList.module'
import PriceListService from './priceList/services/priceList.service'
import { CallbackModule } from './callback/callback.module'
import CallbackService from './callback/services/callback.service'
import AuthUsernameService from './auth/services/authUsername.service'
import AuthPhoneService from './auth/services/authPhone.service'
import TokenService from './auth/services/token.service'
import UserService from './user/services/user.service'
import { PriceListSaleModule } from './priceListSale/priceListSale.module'
import PriceListSaleService from './priceListSale/services/priceListSale.service'

export interface ModuleFactory {
  authModule: AuthModule
  userModule: UserModule
  filialModule: FilialModule
  categoryModule: CategoryModule
  priceListModule: PriceListModule
  priceListSaleModule: PriceListSaleModule
  repairModule: CallbackModule
}

const moduleContext = () => {
  return React.createContext({
    authModule: new AuthModule(
      new AuthUsernameService(new TokenService()),
      new AuthPhoneService(new TokenService()),
      new TokenService()
    ),
    userModule: new UserModule(new UserService()),
    filialModule: new FilialModule(new FilialService(), new CityService()),
    categoryModule: new CategoryModule(new CategoryService()),
    priceListModule: new PriceListModule(new PriceListService()),
    priceListSaleModule: new PriceListSaleModule(new PriceListSaleService()),
    repairModule: new CallbackModule(new CallbackService()),
  })
}

export const useModules = (): ModuleFactory => React.useContext(moduleContext())
