import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

export function setPage(page?: string): void {
  history.push(`/${page || ''}`)
  window.scroll(0, 0)
}

export default history
