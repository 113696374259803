import { injectable } from 'tsyringe'
import config from '../../../config'
import Analytics from '../../../utils/Analytics'
import api from '../../../utils/api'
import urljoin from 'url-join'

export interface IItemPriceListSale {
  id: number
  cash: number
  checkingAccount: number
  finenessOf: number
  category: string
  city: string
  update: boolean //флаг возможности редактирования, если true то можно редактировать
}

export interface IFilterPriceListSale {
  cityId: number
  categoryId: number
}

export type ICreateItemPriceListSale = Omit<IItemPriceListSale, 'id'>

export interface IPriceListSaleService {
  getPriceListSaleByFilter: (
    filter: IFilterPriceListSale
  ) => Promise<IItemPriceListSale[] | undefined>
  getPriceListSale: () => Promise<IItemPriceListSale[] | undefined>
  saveItemPriceListSale: (
    itemPriceListSale: IItemPriceListSale
  ) => Promise<Pick<IItemPriceListSale, 'id'> | undefined>
  savePriceListSale: (
    priceListSale: IItemPriceListSale[],
    categoryId: number,
    cityIds: number[]
  ) => Promise<IItemPriceListSale[] | undefined>
  createItemPriceListSale: (
    itemPriceListSale: ICreateItemPriceListSale
  ) => Promise<Pick<IItemPriceListSale, 'id'> | undefined>
  deleteItemPriceListSale: (
    itemPriceListSale: IItemPriceListSale
  ) => Promise<Pick<IItemPriceListSale, 'id'> | undefined>
}

@injectable()
class PriceListSaleService implements IPriceListSaleService {
  readonly config = {
    urlApi: urljoin(config.API_URL, 'priceListSale'),
  }

  getPriceListSale = async (): Promise<IItemPriceListSale[] | undefined> => {
    try {
      const res = await api.get(urljoin(this.config.urlApi, 'all'))
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о категории ${e}`)
    }
  }

  getPriceListSaleByFilter = async (
    filter: IFilterPriceListSale
  ): Promise<IItemPriceListSale[] | undefined> => {
    try {
      const { cityId, categoryId } = filter
      const res = await api.get(
        urljoin(this.config.urlApi, 'byFilter', `?cityId=${cityId}`, `?categoryId=${categoryId}`)
      )
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о прайсах ${e}`)
    }
  }

  saveItemPriceListSale = async (
    itemPriceListSale: IItemPriceListSale
  ): Promise<Pick<IItemPriceListSale, 'id'> | undefined> => {
    try {
      const res = await api.put(
        urljoin(this.config.urlApi, String(itemPriceListSale.id)),
        itemPriceListSale
      )
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о прайсах ${e}`)
    }
  }

  savePriceListSale = async (
    priceListSale: IItemPriceListSale[],
    categoryId: number,
    cityIds: number[]
  ): Promise<[] | undefined> => {
    const serverTypePriceListSale: {
      [key: number]: {
        cash: number
        checkingAccount: number
      }
    } = {} // кароч бек принимает объект с ключами проб (костыль!!!!)
    for (const item of priceListSale) {
      serverTypePriceListSale[item.finenessOf] = {
        cash: item.cash,
        checkingAccount: item.checkingAccount,
      }
    }

    const res = await api.put(urljoin(this.config.urlApi), {
      priceList: serverTypePriceListSale,
      city: cityIds,
      categoryId: categoryId,
    })
    return res.data.data
  }

  createItemPriceListSale = async (
    itemPriceListSale: ICreateItemPriceListSale
  ): Promise<Pick<IItemPriceListSale, 'id'> | undefined> => {
    try {
      const res = await api.post(urljoin(this.config.urlApi), itemPriceListSale)
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о прайсах ${e}`)
    }
  }

  deleteItemPriceListSale = async (
    itemPriceListSale: IItemPriceListSale
  ): Promise<Pick<IItemPriceListSale, 'id'> | undefined> => {
    try {
      const res = await api.delete(urljoin(this.config.urlApi, String(itemPriceListSale.id)))
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о прайсах ${e}`)
    }
  }
}

export default PriceListSaleService
