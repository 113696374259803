import { injectable } from 'tsyringe'
import config from '../../../config'
import Analytics from '../../../utils/Analytics'
import api from '../../../utils/api'
import urljoin from 'url-join'
import { IFilial } from '../../filial/services/filial.service'

export interface IPaginationParams {
  page?: number
  limit?: number
}

export interface ICallback {
  id: number
  fullName: string
  phone: string
  comment?: string
  filial?: IFilial
  createdAt: string
}

export interface IResponseCallback {
  data: ICallback[] | []
  pageCurrent: number
  total: number
  pageCount: number
  message: number
  code: number
}

export interface ICallbackService {
  getCallbacks: (paginationParams?: IPaginationParams) => Promise<IResponseCallback | undefined>
}

@injectable()
class CallbackService implements ICallbackService {
  readonly config = {
    urlApi: urljoin(config.API_URL, 'callback'),
  }

  getCallbacks = async (
    paginationParams?: IPaginationParams
  ): Promise<IResponseCallback | undefined> => {
    try {
      let paginationUrlStr = ''
      const page = paginationParams?.page
      const limit = paginationParams?.limit
      if (limit || page) {
        paginationUrlStr = '?'
        paginationUrlStr += page ? `page=${page}&` : ''
        paginationUrlStr += limit ? `limit=${limit}` : ''
      }

      const res = await api.get(urljoin(this.config.urlApi, 'all', paginationUrlStr))
      return res.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о категории ${e}`)
    }
  }
}

export default CallbackService
