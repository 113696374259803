import React from 'react'
import { Redirect, Route, Router, Switch } from 'react-router'
import ROUTES from './config/routes'
import { Login } from './pages/Login/Login'
import Filials from './pages/Filial/Filials'
import PriceLists from './pages/PriceList/PriceLists'
import Requests from './pages/Requests/Requests'
import { FilialCreate } from './pages/Filial/FilialCreate'
import { FilialEdit } from './pages/Filial/FilialEdit'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { QueryClient, QueryClientProvider } from 'react-query'

import history from './config/history'
import { Header } from './components/Header/Header'
import AuthProvider from './context/authProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      cacheTime: 20 * 60 * 1000,
    },
  },
})

const RedirectToBasePath = () => (
  <Redirect
    to={{
      pathname: ROUTES.ADMIN.path,
      search: window.location.search,
    }}
  />
)

export default class App extends React.Component {
  render(): React.ReactNode {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Router history={history}>
              <Header />
              <Switch>
                <Route path={'/login'} component={Login} />
                <Route exact path={'/'} component={PriceLists} />
                <Route path={'/priceList/:typePrice'} component={PriceLists} />
                <Route path={'/requests'} component={Requests} />
                <Route exact path={'/filials'} component={Filials} />
                <Route exact path={'/filials/create/:cityId'} component={FilialCreate} />
                <Route exact path={'/filials/edit/:cityId/:id'} component={FilialEdit} />
                <Route path="*" component={RedirectToBasePath} />
              </Switch>
            </Router>
          </AuthProvider>
        </QueryClientProvider>
      </MuiPickersUtilsProvider>
    )
  }
}
