import { inject, injectable, registry } from 'tsyringe'
import PriceListService, {
  IPriceListService,
  IItemPriceList,
  ICreateItemPriceList,
  IFilterPriceList,
} from './services/priceList.service'

@injectable()
@registry([
  {
    token: 'priceListService',
    useClass: PriceListService,
  },
])
export class PriceListModule {
  constructor(@inject('priceListService') private priceListService: IPriceListService) {}

  getPriceList = async (): Promise<IItemPriceList[] | undefined> => {
    return await this.priceListService.getPriceList()
  }

  getPriceListByFilter = async (
    filter: IFilterPriceList
  ): Promise<IItemPriceList[] | undefined> => {
    return await this.priceListService.getPriceListByFilter(filter)
  }

  saveItemPriceList = async (
    itemPriceList: IItemPriceList
  ): Promise<Pick<IItemPriceList, 'id'> | undefined> => {
    return await this.priceListService.saveItemPriceList(itemPriceList)
  }

  savePriceList = async (
    priceList: IItemPriceList[],
    categoryId: number,
    cityIds: number[]
  ): Promise<IItemPriceList[] | undefined> => {
    return await this.priceListService.savePriceList(priceList, categoryId, cityIds)
  }

  createItemPriceList = async (
    itemPriceList: ICreateItemPriceList
  ): Promise<Pick<IItemPriceList, 'id'> | undefined> => {
    return await this.priceListService.createItemPriceList(itemPriceList)
  }

  deleteItemPriceList = async (
    itemPriceList: IItemPriceList
  ): Promise<Pick<IItemPriceList, 'id'> | undefined> => {
    return await this.priceListService.deleteItemPriceList(itemPriceList)
  }

  calculatePriceList = async (
    priceList: IItemPriceList[],
    mainItemPriceList: IItemPriceList
  ): Promise<IItemPriceList[] | undefined> => {
    return await this.priceListService.calculatePriceList(priceList, mainItemPriceList)
  }
}
