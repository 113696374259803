import { inject, injectable, registry } from 'tsyringe'
import AuthUsernameService, {
  IAuthUsernameService,
  ISignInBasicValues,
  ISignInUsernameValues,
} from './services/authUsername.service'

import AuthPhoneService, {
  IAuthPhoneService,
  IIsRegisteredRes,
  IIsRegisteredByPhoneValues,
  ISignInPhoneValues,
} from './services/authPhone.service'
import TokenService, { IToken, ITokenService } from './services/token.service'

@injectable()
@registry([
  {
    token: 'authUsernameService',
    useClass: AuthUsernameService,
  },
  {
    token: 'tokenService',
    useClass: TokenService,
  },
  {
    token: 'authPhoneService',
    useClass: AuthPhoneService,
  },
])
export class AuthModule {
  constructor(
    @inject('authUsernameService') public authUsernameService: IAuthUsernameService,
    @inject('authPhoneService') private authPhoneService: IAuthPhoneService,
    @inject('tokenService') private tokenService: ITokenService
  ) {}

  isRegistered = async (
    data: IIsRegisteredByPhoneValues
  ): Promise<IIsRegisteredRes | undefined> => {
    return await this.authPhoneService.isRegistered(data)
  }

  signIn = async (data: ISignInPhoneValues): Promise<IToken | undefined> => {
    const res = await this.authPhoneService.fastSignIn(data)
    if (res) {
      return this.tokenService.setToken(res)
    }
  }

  signInUsername = async (data: ISignInUsernameValues): Promise<IToken | undefined> => {
    const res = await this.authUsernameService.signInUsername(data)
    if (res) {
      return this.tokenService.setToken(res)
    }
  }

  signInBasic = async (data: ISignInBasicValues): Promise<string | undefined> => {
    const res = await this.authUsernameService.signInBasic(data)
    if (res) {
      return await this.tokenService.setBasicToken(res)
    }
  }
}
